.form_field_main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}
.form_field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}

.form_field:last-child {
    margin-bottom: 0;
}

.form_field label {
    font-weight: 700;
    display: flex;
}

.field-left {
    width: 100%;
    padding-right: 15px;
}

.field-right {
    width: 100%;
    text-align: start;
    margin-bottom: 0px;
    padding-top: 7px;
    position: relative;
}

.custom-radio {
    position: relative;
}

.custom-radio input[type="radio"] {
    position: absolute;
    top: 0;
    left: -999999px;
}

.field-right .custom-radio {
    display: inline-block;
    vertical-align: middle;
}

 .custom-radio label:hover,
 .custom-radio label:active,
 .custom-radio input[type="radio"]+label:hover,
 .custom-radio input[type="radio"]+label:active {
    font-weight: 700;
}

.custom-radio input[type="radio"]+label {
    position: relative;
    padding-left: 40px;
    visibility: visible !important;
    line-height: 20px;
    cursor: pointer;
    font-weight: 400;
}

.custom-radio input[type="radio"]+label:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 100%;
}
.custom-radio input[type="radio"]+label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid rgb(51, 51, 223) ;
    border-radius: 100%;
}

.custom-radio input[type="radio"]+label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border: 6px solid rgb(51, 51, 223);
    border-radius: 100%;
    transform: scale(0);
}

.custom-radio input[type="radio"]:checked+label:after {
    transform: scale(1);
}

.custom-radio+.custom-radio {
    margin-left: 25px;
}