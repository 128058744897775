.DotPagination-slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: auto;
}


.DotPagination-pagination {
  display: flex;
  justify-content: center;
  margin: 4px 0px;
}


.DotPagination-pagination button:focus {
  outline: none;
}

.DotPagination-pagination button:hover {
  background-color: var(--grayColor);
}

.DotPagination-pagination button {
  border: none;
  background-color: var(--garycardcolor);
  margin: 0 5px;
  padding: 3px;
  cursor: pointer;
  border-radius: 50%;
  width: 5x;
  height: 5px;
  transition: background-color 0.5s ease-in;
  /* Add transition */
}

.DotPagination-pagination button.active {
  background-color: var(--lightBlackColor);
}


@media (max-width: 1330px) {

  .DotPagination-pagination {
    margin-left: 100px;
  }
}  