.image-slider {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: auto;
}

.MuiSvgIcon-root {
  color: black;
}

.slick-next:before {
  content: var(--nextImage);
  height: 20px;
  width: 20px;
}

.slick-prev:before {
  content: var(--preImage);
}

.slick-prev {
  left: -55px;
}

.slick-prev:before,
.slick-next:before {
  /* font-size: 20px; */
  /* height: 20px; */
  /* width: 20px; */
  /* line-height: 1; */
  opacity: .75;
  /* color: white; */
  position: absolute;
  bottom: 10px;
}