.main {
  width: 100%;
  margin-bottom: 71px;
  background-color: var(--whiteColor);
  overflow: auto;
}

* {
  font-family: 'Nunito', sans-serif;
}

body {
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  width: 100%;
  flex-direction: column;
  vertical-align: top;
}

.MuiButton-contained.Mui-disabled {
  color: var(--whiteColor) !important;
}

legend {
  float: none;
}

.fromcantrol-input {
  background-color: var(--inputBgColor);
  border-radius: 10px;
}

.MuiTab-wrapper {
  font-size: 18;
  font-weight: 900;
  color: var(--ButtonColor);
}

.pagetitle {
  display: flex;
  justify-content: space-between;
}

.pagetitle-left {
  margin-top: 5px;
}

.popup-hr {
  margin: 0.5rem 0;
}

.red-dot-container {
  display: flex;
}

.red-dot {
  width: 7px;
  height: 7px;
  background-color: var(--redColor);
  border-radius: 50%;
}


.my-facebook-button-class {
  background: #4267b2 !important;
  border-radius: 5px !important;
  height: 40px !important;
  text-align: center !important;
  width: 200px !important;
  font-size: 13px !important;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #FFFFFF;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
  line-height: 20px;
  -webkit-appearance: none;
  cursor: pointer;
}

.my-instagram-button-class {
  background: #f09433 !important;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;

  border-radius: 5px !important;
  color: white !important;
  height: 40px !important;
  text-align: center !important;
  width: 200px !important;
  font-size: 13px !important;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.text-pointer-none {
  cursor: none;
  pointer-events: none;
}

/* 
.MuiButton-label {
  min-width: 80px;
} */

@media (max-width: 1500px) {}

@media (max-width: 1199px) {}

@media (max-width: 991px) {}

@media (max-width: 768px) {}