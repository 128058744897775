@media only screen and (max-width: 1330px) {
   
   .pagetitle-left-header{
    width: 50% !important;
   }
    
}
@media only screen and (max-width: 1330px) {
   
   .pagetitle-center-header{
    width: 30% !important;
    justify-content: start !important;
   }
   .pagetitle-right-header{
    width: 20% !important;
   }
    
}