.calculator-main-tabView {
    display: flex;
    justify-content: space-evenly;

    font-size: 12px;
    text-align: center;
    border: 1px solid var(--main);
    border-radius: 5px;
}

.calculator-tabView-active {

    background: var(--main);
    padding: 3px 19px;
    border-radius: 3px;
    color: var(--white);
    font-weight: 600;
}

.calculator-tabView-deActive {
    margin: 0px 10px;
    background: var(--hover-font-color);
    padding: 3px 0px;

    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}


.content-box-calendar {
    /* background: var(--hover-font-color); */
    /* border: 1px solid #ebebeb; */
    position: relative;
    margin-bottom: 5px;
    /* width: 110px; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
}

.content-box-calendar:after {
    content: "";
    /* position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0px 8px 99px rgba(0, 0, 0, 0.06);
    transition: all 0.5s ease-in-out;
    opacity: 0;
}

.button-hover {
    opacity: 0;
    padding: 5% 10px;
    display: grid;
    align-self: center
}

.content-box-calendar:hover:after,
.content-box-calendar:focus:after {
    opacity: 1;
}

.content-box-calendar:hover .box-image-calendar:after,
.content-box-calendar:focus .box-image-calendar:after {
    opacity: 0.7;
}

.content-box-calendar:hover .button-hover,
.content-box-calendar:focus .button-hover {
    opacity: 1;
}



.calendar-image-box {
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-size: 160px;
    height: 165px;
    width: 165px;
    /* position: relative; */
    display: grid;
    justify-items: center;
}

.box-image-calendar:after {
    content: "";
    position: absolute;
    top: -10px;
    right: -4px;
    bottom: 0;
    left: -4px;
    opacity: 0.7;
    background: #0a0a0a;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    /* z-index: -1; */
}

.button-field-calendar {
    position: relative;
    z-index: 1;
    background: #007b88;
    background: var(--main-blue-color);
    background: linear-gradient(90deg, #007b88 4%, #005e68);
    border-radius: 50px;
    display: inline-block;
    vertical-align: middle;
    margin: 1%;
}



.calendar-hover-button {
    font-weight: 500;
    color: var(--white-color);
    border-radius: 50px;
    min-width: 100px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    border: 1px solid transparent;
    font-family: var(--font-family-new);
    background: transparent;
    line-height: 20px;
    padding: 5px 10px;
    font-size: 14px;
    -webkit-appearance: none;
    cursor: pointer;
}


.customcalender-main {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.customcalender-submain {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.customcalender-pagesubtitle {
    width: 100%;
}

.customcalender-daynamecontent {
    border: 0px solid #d3d3d3;
    padding: 10px;
    min-width: 900px;
    overflow: hidden;
    padding: 0 6%;
}

.customcalender-dayname {
    display: flex;
    width: 100%;
    margin-left: 13px;
}

#CalenderTopTitleContentView-day {
    margin-right: 6px;
    max-width: 13.65%;
}

.customcalender-date {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 13px;
}

#calender-content-view-gray {
    background-color: #D3D3D3;
    width: 13.65%;
    margin-right: 6px;
    height: 200px;
    margin-bottom: 5px;
}

#calender-content-view-white {
    background-color: #fcfcfc;
    width: 13.65%;
    margin-right: 6px;
    height: 200px;
    margin-bottom: 5px;
}
.customcalender-mapincss{
    display: flex;
    width: 100%;
    justify-content: center;
}
.customcalender-divider{
    width: 100%;
    height: 1px;
    margin-top: 2px;
    margin-bottom: 5px;
}